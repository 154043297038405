import React from 'react';
import './QuestionSuggestion.scss';
import Questions from './Question';

export default class QuestionSuggestion extends React.Component {
  handleQuestionSelect = value => {
    const { onSendMessage, setSuggestionsDisplayStatus } = this.props;
    onSendMessage(value);
    setSuggestionsDisplayStatus(false);
  };

  render() {
    const { suggestedQuestions } = this.props;
    const questionComponents = [];
    if (suggestedQuestions.length > 4) {
      suggestedQuestions.splice(4, suggestedQuestions.length - 4);
    }
    suggestedQuestions.forEach(ques => {
      questionComponents.push(<Questions question={ques} handleQuestionSelect={this.handleQuestionSelect} />);
    });
    return <div className="question-suggestions">{questionComponents}</div>;
  }
}
