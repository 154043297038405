import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CandidateChatWindow from './Containers/CandidateChatWindow';
import UnsubscribeContainer from './Containers/Unsubscribe';
import SubscribeSuccess from './Components/SubscribeSuccess/SubscribeSuccess';
import UnsubscribeSuccessContainer from './Containers/UnsubScribeSuccess';
import OptoutContainer from './Containers/Optout';
import OptinContainer from './Containers/Optin';
import OptoutSuccess from './Containers/OptoutSuccess';
import OptinSuccess from './Components/OptinSuccess/OptinSuccess';
import EmailVerificationContainer from './Containers/EmailVerificaton';

function ErrorPage() {
  return <div>Invalid URL</div>;
}
// eslint-disable-next-line react/prefer-stateless-function
class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/chat" component={CandidateChatWindow} />
          <Route exact path="/unsubscribesuccess" component={UnsubscribeSuccessContainer} />
          <Route exact path="/unsubscribe" component={UnsubscribeContainer} />
          <Route exact path="/subscribesuccess" component={SubscribeSuccess} />
          <Route exact path="/optout" component={OptoutContainer} />
          <Route exact path="/optin" component={OptinContainer} />
          <Route exact path="/optinsuccess" component={OptinSuccess} />
          <Route exact path="/optoutsuccess" component={OptoutSuccess} />
          <Route exact path="/emailverification" component={EmailVerificationContainer} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    );
  }
}

export default connect()(Routes);
