import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { getEmailVerificationActionStatus, getEmailVerificationStatus } from '../Reducers/EmailVerificationReducer';
import { getWhiteLabelInformation } from '../Reducers/ConfigReducer';
import verifyEmail from '../Actions/EmailVerificationActions';
import fetchAndSetWhiteLabelInfo from '../Actions/ConfigActions';
import EmailVerification from '../Components/EmailVerification/EmailVerification';

const mapStateToProps = state => ({
  verificationActionStatus: getEmailVerificationActionStatus(state),
  verificationStatus: getEmailVerificationStatus(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  verifyEmailFromUrl: verifyEmail,
  fetchWhiteLabelInfo: fetchAndSetWhiteLabelInfo,
};

class EmailVerificationContainer extends React.Component {
  componentDidMount() {
    const { verificationId, appId, otp, whiteLabelId } = queryString.parse(window.location.search);
    const { verifyEmailFromUrl, fetchWhiteLabelInfo } = this.props;
    fetchWhiteLabelInfo(whiteLabelId);
    verifyEmailFromUrl({ verificationId, appId, otp });
  }

  render() {
    const { verificationStatus, verificationActionStatus, whiteLabelInfo } = this.props;
    return (
      <EmailVerification
        verificationStatus={verificationStatus}
        verificationActionStatus={verificationActionStatus}
        whiteLabelInfo={whiteLabelInfo}
      />
    );
  }
}

export { EmailVerificationContainer as EmailVerificationWithoutStore };

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationContainer);
