import React from 'react';
import { Form, Divider, Button, Checkbox } from 'antd';
import { Helmet } from 'react-helmet';
import './Optout.scss';

class Optout extends React.Component {
  handleSubmit = e => {
    const { form, updateStatus } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (values.unsubscribeTypes.includes('all')) {
          updateStatus(true);
        } else {
          updateStatus(false);
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const isDisbale = (getFieldValue('unsubscribeTypes') || []).length > 0;

    return (
      <div>
        <Helmet>
          <title>Optout</title>
          <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
        </Helmet>

        <div className="optout-oval">
          <img src={`${process.env.PUBLIC_URL}/unsubscribe.svg`} alt="unsubscribe" className="optout-icon" />
        </div>
        <div className="optout">
          <div className="optout-wrapper">
            <div className="optout-content">
              <div className="optout-are-you-sure">Are you sure ?</div>
              <div className="optout-message">
                This means you will no longer receive communications about open positions relevant to your experience,
                skills, and career development.
              </div>
              <Divider />
              <div className="optout-do-you-really">
                Do you really want to give up taking a look at new career opportunites now and then?
              </div>
              <Form className="optout-form" onSubmit={this.handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('unsubscribeTypes', {
                    initialValue: ['all'],
                  })(
                    <Checkbox.Group>
                      <div className="optout-div-wrapper">
                        {/* <div>
                          <Checkbox value="this" className="optout-option-text">
                            Unsubscribe me from emails about this job
                          </Checkbox>
                        </div> */}
                        <div>
                          <Checkbox value="all" className="optout-option-text">
                            Unsubscribe me from all emails
                          </Checkbox>
                        </div>
                      </div>
                    </Checkbox.Group>
                  )}
                </Form.Item>
                <div className="optout-submit-wrapper ">
                  <div>
                    <Form.Item>
                      <Button disabled={!isDisbale} htmlType="submit" className="optout-button">
                        Opt-out
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <div className="optout-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(Optout);
