import _ from 'lodash';

const initialState = {
  messages: [],
  totalMessages: 0,
  isMessageFromUser: false,
  pendingMessagesById: [],
};

function ChatReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'ADD_MESSAGE':
      newState = _.cloneDeep(state);
      if (!newState.messages) {
        newState.messages = [];
      }
      if (_.includes(newState.pendingMessagesById, action.payload.Id)) {
        newState.messages.splice(
          _.findIndex(newState.messages, message => {
            return message.Id === action.payload.Id;
          }),
          1,
          action.payload
        );
      } else {
        newState.messages.unshift(action.payload);
        newState.totalMessages += 1;
        newState.botTypingStatus = action.payload.IsByPerson;
      }
      return newState;
    case 'ADD_PENDING_MESSAGE_BY_ID':
      newState = _.cloneDeep(state);
      newState.pendingMessagesById.unshift(action.payload);
      return newState;
    case 'ADD_MESSAGES':
      newState = _.cloneDeep(state);
      if (!newState.messages) {
        newState.messages = [];
      }
      newState.messages.push(...action.payload.Messages);
      newState.totalMessages = action.payload.Total;
      return newState;
    case 'SET_IS_MESSAGE_FROM_USER':
      newState = _.cloneDeep(state);
      newState.isMessageFromUser = action.payload;
      return newState;
    case 'SET_BOT_DETAILS':
      newState = _.cloneDeep(state);
      newState.botDetails = action.payload;
      return newState;
    case 'SET_APPOINTMENT_SUGGESTIONS':
      newState = _.cloneDeep(state);
      newState.validSlots = action.payload;
      newState.showSuggestions = true;
      return newState;
    case 'SET_SUGGESTIONS_STATUS':
      newState = _.cloneDeep(state);
      newState.showSuggestions = action.payload;
      return newState;
    case 'CLEAR_VALID_SLOTS':
      newState = _.cloneDeep(state);
      newState.validSlots = [];
      return newState;

    case 'SET_BOT_STATUS':
      newState = _.cloneDeep(state);
      newState.isBotEnabled = action.payload.isBotEnabled;
      return newState;
    default:
      return state;
  }
}

function getMessages(state) {
  return state.ChatReducer.messages;
}

function getBotContextId(state) {
  if (state.ChatReducer.messages && state.ChatReducer.messages.length)
    return state.ChatReducer.messages[0].BotContextId;
  return null;
}

function getBotData(state) {
  return state.ChatReducer.botDetails;
}

function getValidSlots(state) {
  return state.ChatReducer.validSlots;
}

function getSuggestionStatus(state) {
  return state.ChatReducer.showSuggestions;
}

function getBotTypingStatus(state) {
  return state.ChatReducer.botTypingStatus;
}

function getBotEnabledStatus(state) {
  return state.ChatReducer.isBotEnabled;
}

function getTotalMessagesCount(state) {
  return state.ChatReducer.totalMessages;
}

function getIsMessageFromUser(state) {
  return state.ChatReducer.isMessageFromUser;
}

export {
  ChatReducer,
  getBotContextId,
  getMessages,
  getBotData,
  getValidSlots,
  getSuggestionStatus,
  getBotTypingStatus,
  getBotEnabledStatus,
  getTotalMessagesCount,
  getIsMessageFromUser,
};
