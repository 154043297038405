import _ from 'lodash';
import uuid from 'uuid';
import { chatConnection } from '../Events/SocketConnectionManager';
import getMessages from '../Repository/ChatMessageRepository';
import * as BotRepository from '../Repository/BotRepository';
import { getTimeInMinutes } from '../Utils/DateUtils';
import { isValidDayForUser, getNormalizedUserStartAndEndTime, getValidSlotDateTime } from '../Utils/SlotUtils';

function addMessage(message) {
  return {
    type: 'ADD_MESSAGE',
    payload: message,
  };
}

function addPendingMessageId(messageId) {
  return {
    type: 'ADD_PENDING_MESSAGE_BY_ID',
    payload: messageId,
  };
}

function sendMessage({ personId, botContextId, message, conversationId }) {
  return dispatch => {
    const messageObject = {
      From: personId,
      MessageType: 'Chat',
      BotContextId: botContextId,
      Body: message,
      ConversationId: conversationId,
      Id: uuid.v4(),
      IsByPerson: true,
    };
    if (chatConnection) {
      chatConnection.invoke('SendChatMessage', messageObject);
    }
    dispatch(
      addMessage({
        ...messageObject,
        IsByBot: false,
        RequestStatus: 'Pending',
      })
    );
    dispatch(addPendingMessageId(messageObject.Id));
  };
}

function setIsMessageFromUser(isMessageFromUser) {
  return {
    type: 'SET_IS_MESSAGE_FROM_USER',
    payload: isMessageFromUser,
  };
}

function addMessages(messages) {
  return {
    type: 'ADD_MESSAGES',
    payload: messages,
  };
}

function fetchMessages(conversationId, page = 0, size = 30) {
  return async dispatch => {
    const response = await getMessages(conversationId, page, size);
    dispatch(addMessages(response.data));
    const messageFromUser = _.find(response.data.Messages, message => {
      return !message.IsByPerson && !message.IsByBot && _.get(message, ['CreatedBy'], false);
    });
    if (messageFromUser) {
      dispatch(setIsMessageFromUser(true));
    } else {
      dispatch(setIsMessageFromUser(false));
    }
  };
}

function setSuggestionsDisplayStatus(value) {
  return dispatch => {
    dispatch({ type: 'SET_SUGGESTIONS_STATUS', payload: value });
  };
}

function setValidSlotsEmpty() {
  return dispatch => {
    dispatch({ type: 'CLEAR_VALID_SLOTS' });
  };
}

function setBotStatus(isBotEnabled) {
  return {
    type: 'SET_BOT_STATUS',
    payload: { isBotEnabled },
  };
}

function getBotDetails(conversationId) {
  return dispatch => {
    BotRepository.getBotContextId(conversationId)
      .then(response => {
        const botContextId = response.data.BotContextId;
        dispatch(setBotStatus(response.data.IsBotEnabled));
        BotRepository.getUserEmail(response.data.CreatedBy).then(userDetailsResponse => {
          dispatch({
            type: 'SET_USER_DETAILS',
            payload: userDetailsResponse.data,
          });
        });
        return botContextId;
      })
      .then(botContextId => {
        BotRepository.getBotDetails(botContextId).then(response => {
          dispatch({
            type: 'SET_BOT_DETAILS',
            payload: response.data,
          });
        });
      });
  };
}

function getCalendarFreeSlots(userId, startDate, endDate) {
  return dispatch => {
    BotRepository.getCalendarSlots(userId, startDate, endDate)
      .then(response => {
        const freeSlots = response.data;
        return freeSlots;
      })
      .then(freeSlots => {
        BotRepository.getUserAvailabilityConfig(userId).then(response => {
          const userCalendarConfig = response.data;

          const userSlotDuration = userCalendarConfig.SlotDuration;
          const { userStartTimeMinutes, userEndTimeMinutes } = getNormalizedUserStartAndEndTime(userCalendarConfig);
          const validSlots = [];
          for (let i = 0; i < freeSlots.length; i += 1) {
            const slotStartDateTime = new Date(freeSlots[i].start_date_time);

            const slotEndDateTime = new Date(freeSlots[i].end_date_time);
            slotStartDateTime.setMinutes(slotStartDateTime.getMinutes() - userStartTimeMinutes);
            slotEndDateTime.setMinutes(slotEndDateTime.getMinutes() - userStartTimeMinutes);
            while (slotStartDateTime < slotEndDateTime) {
              const validSlotStartMinutes = getTimeInMinutes(slotStartDateTime);
              const slotEndTimeMinutes = getTimeInMinutes(slotEndDateTime);
              const validSlotEndMinutes = validSlotStartMinutes + userSlotDuration;
              const date = new Date();
              const slotStartTime = new Date(
                slotStartDateTime.getFullYear(),
                slotStartDateTime.getMonth(),
                slotStartDateTime.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
              );
              if (
                validSlotStartMinutes >= 0 &&
                userEndTimeMinutes >= validSlotEndMinutes &&
                validSlotEndMinutes <= slotEndTimeMinutes &&
                isValidDayForUser(userCalendarConfig, slotStartDateTime) &&
                slotStartTime.getTime() > date.getTime()
              ) {
                validSlots.push(
                  getValidSlotDateTime(slotStartDateTime, validSlotStartMinutes, userStartTimeMinutes, userSlotDuration)
                );
              }
              slotStartDateTime.setMinutes(slotStartDateTime.getMinutes() + userSlotDuration);
            }
          }
          dispatch({
            type: 'SET_APPOINTMENT_SUGGESTIONS',
            payload: validSlots,
          });
        });
      });
  };
}

export {
  fetchMessages,
  sendMessage,
  getBotDetails,
  setSuggestionsDisplayStatus,
  setValidSlotsEmpty,
  getCalendarFreeSlots,
  setBotStatus,
  addMessage,
  setIsMessageFromUser,
};
