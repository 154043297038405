import fetchWhiteLabelInfo from '../Repository/ConfigRepository';
import { setWhiteLabelData } from './ActionCreators/ConfigActions';

export default function fetchAndSetWhiteLabelInfo(client) {
  return dispatch => {
    if (client) {
      fetchWhiteLabelInfo(client).then(whiteLabelResponse => {
        dispatch(setWhiteLabelData(whiteLabelResponse.data));
      });
    }
  };
}
