import { Button, Icon, Skeleton } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import AppLogo from '../AppLogo/AppLogo';
import './EmailVerification.scss';

export default function EmailVerification(props) {
  const { verificationStatus, verificationActionStatus, whiteLabelInfo } = props;
  const { status, message, application } = verificationStatus;
  const redirectUrl = _.get(application, ['RedirectUrl'], '');
  const redirectApplicationName = _.get(whiteLabelInfo, ['AppName'], '') || _.get(application, ['Name'], '');
  const backgroundColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
  const currentMessage = (message || '').replace(/{{ApplicationName}}/g, redirectApplicationName || 'Arya');

  let verificationTitle = (
    <div className="email-verification-text-success">Verifying your email address. Please wait...</div>
  );
  let verificationIcon = null;
  const isVerificationActionSuccessfull = verificationActionStatus.toLowerCase() === 'successfull';

  if (isVerificationActionSuccessfull) {
    if (status && status.toLowerCase() === 'verified') {
      verificationTitle = <div className="email-verification-text-success">Email Verified Successfully</div>;
      verificationIcon = <Icon type="check-circle" className="email-verification-icon-success" />;
    } else {
      verificationTitle = <div className="email-verification-text-failed">Email Verification Failed</div>;
      verificationIcon = <Icon type="close-circle" className="email-verification-icon-failed" />;
    }
  }

  return (
    <div className="email-verification-wrapper">
      <Helmet>
        <title>Email Verification</title>
        <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />
      </Helmet>
      <div className="email-verification">
        <div className="email-verification-logo" style={{ backgroundColor }}>
          <AppLogo />
        </div>
        <div className="email-verification-text-content">
          <div className="email-verification-text-content-title">
            {verificationIcon}
            {verificationTitle}
          </div>
          <Skeleton active loading={!isVerificationActionSuccessfull} paragraph={{ rows: 0 }}>
            <div className="email-verification-text-content-message">{currentMessage}</div>
          </Skeleton>
          {redirectUrl ? (
            <Button shape="round" type="primary" className="email-verification-text-content-button" href={redirectUrl}>
              {`Back${redirectApplicationName ? ` to ${redirectApplicationName}` : ''}`}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
