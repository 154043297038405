import React from 'react';
import { Button, Radio, Tooltip } from 'antd';
import styles from './Unsubscribe.module.scss';

const Unsubscribe = ({
  candidateEmail,
  jobTitle,
  jobId,
  jobLocation,
  candidateName,
  state,
  onChangeUnsubscribeOptions,
  onSubmitUnsubscribeCandidate,
  unSubscribeOptions,
}) => {
  function onChange(e) {
    const { value } = e.target;
    onChangeUnsubscribeOptions(value);
  }
  const { pageState, unsubscribeValue } = state;

  const selected = {
    backgroundColor: '#13c26b',
    color: 'white',
  };

  const notSelected = {
    backgroundColor: '#f5f5f5',
    color: 'rgba(0, 0, 0, 0.25)',
    border: '1px solid',
  };

  if (pageState === 'initial') {
    return (
      <div className={styles.unsubscribeOptionContainer}>
        <div className={styles.title}>
          <h2>Unsubscribe Candidate</h2>
        </div>
        <hr className={styles.lineSeperator} />
        <div className={styles.unsubscribeContent}>
          <p>
            Are you sure to unsubcribe the candidate <b>{candidateName}</b> <b>({candidateEmail})</b> from
          </p>
          <p>
            Job Title -{' '}
            <b>
              {jobTitle} (Job ID: {jobId})
            </b>
          </p>
          <p className={styles.location}>
            Job Location - <b>{jobLocation}</b>
          </p>
        </div>
        <div className={styles.subscribeOptions}>
          <Radio.Group className={styles.unsubscribeRadioGroup} onChange={onChange}>
            {unSubscribeOptions().map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className={styles.actionButtons}>
          <Tooltip title="please select an option" placement="bottom">
            <Button
              className={styles.confirm}
              type="primary"
              htmlType="submit"
              shape="round"
              style={unsubscribeValue ? selected : notSelected}
              onClick={onSubmitUnsubscribeCandidate}
            >
              Confirm
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
  if (pageState === 'failed') {
    return (
      <div className={styles.unsubscribeSuccess}>
        <h2>Something Went Wrong !</h2>
        <p>Please try again</p>
      </div>
    );
  }
  if (pageState === 'success') {
    return (
      <div className={styles.unsubscribeSuccess}>
        <h2>Unsubscribed !</h2>
        <p>
          The candidate have been successfully removed from Leoforce subscriber list. And will no longer hear from us.
        </p>
      </div>
    );
  }
  return <div></div>;
};

export default Unsubscribe;
