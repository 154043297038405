const moment = require('moment');

function getDateString(date) {
  const dateString = moment(date).format('YYYY-MM-DD');
  return dateString;
}

function addDaysToDate(date, numberOfDays) {
  return new Date(date.setDate(date.getDate() + numberOfDays));
}

function getTimeInMinutes(dateTime) {
  return dateTime.getHours() * 60 + dateTime.getMinutes();
}

export { addDaysToDate, getDateString, getTimeInMinutes };
