import React from 'react';
import { Helmet } from 'react-helmet';
import { Divider, Button } from 'antd';
import './UnsubscribeSuccess.scss';

export default function UnsubscribeSuccess(props) {
  return (
    <div>
      <Helmet>
        <title>Subscribe</title>
        <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
      </Helmet>
      <div className="unsubscribed">
        <div className="unsubscribed-oval">
          <img src={`${process.env.PUBLIC_URL}/unsubscribed.svg`} alt="unsubscribed" className="unsubscribed-icon" />
        </div>
        <div className="unsubscribed-missed">You will be missed!</div>
        <div className="unsubscribed-message">
          You have been successfully removed from Leoforce subscriber list. You will no longer hear from us.
        </div>
        <Divider />
        <div className="unsubscribed-confirmation">
          Didn’t mean to unsubscribe? No problem, Re-subscribe to this list with a single click.
        </div>
        <div className="unsubscribed-button-wrapper">
          <Button type="primary" onClick={() => props.updateStatus()} className="unsubscribed-button">
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  );
}
