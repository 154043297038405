import React from 'react';
import { Icon, Input, Button } from 'antd';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import ChatPane from '../ChatPane/ChatPane';
import './CandidateChatWindow.scss';
import BotSuggestionsContainer from '../../Containers/BotSuggestions';
import getMessageSourceName from '../../Utils/MessageUtils';

const { TextArea } = Input;
const { MessageBubble } = ChatPane;

class CandidateChatWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageValue: null,
      isMessageAllowed: true,
    };
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onClickSendMessage = this.onClickSendMessage.bind(this);
    this.onSuggestionSelect = this.onSuggestionSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props;
    const isLastMessageByBot = _.get(messages, [0, 'IsByBot'], false);
    if (prevProps.messages.length !== messages.length && isLastMessageByBot) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isMessageAllowed: true,
      });
    }
  }

  onMessageChange(e) {
    this.setState({
      messageValue: e.target.value,
    });
  }

  onClickSendMessage(e) {
    e.preventDefault();
    const { messageValue } = this.state;
    const { onSendMessage } = this.props;
    if (messageValue && messageValue.trim().length) {
      onSendMessage(messageValue);
    }
    this.setState({
      messageValue: null,
      isMessageAllowed: false,
    });
  }

  onSuggestionSelect(messageValue) {
    const { onSendMessage } = this.props;
    onSendMessage(messageValue);
    this.setState({
      messageValue: null,
    });
  }

  render() {
    const { messageValue, isMessageAllowed } = this.state;
    let { messages } = this.props;
    const {
      botDetails,
      userDetails,
      botTypingStatus,
      isBotEnabled,
      fetchMessages,
      totalMessagesCount,
      isMessageFromUser,
    } = this.props;
    const favIcon = _.get(botDetails, 'Favicon', undefined);
    const icon = _.get(botDetails, 'Icon', undefined);
    const name = _.get(botDetails, 'Name', 'Arya');
    const title = _.get(botDetails, 'Title', 'AryaBot');
    let isEnabled = false;
    isEnabled = messageValue && messageValue.trim().length && (isMessageAllowed || isMessageFromUser);
    if (!messages) {
      messages = [];
    }
    let botTypingDisplayValue = null;
    if (botTypingStatus && isBotEnabled) {
      botTypingDisplayValue = (
        <div className="typing-indicator-container">
          <div className="typing-indicator-block">
            <div className="typing-indicator-dot" />
            <div className="typing-indicator-dot" />
            <div className="typing-indicator-dot" />
          </div>
        </div>
      );
    }
    let botSuggestionsContainerValue = null;
    if (_.get(messages, [0, 'BotSuggestions'], []).length > 0 && _.get(userDetails, 'Email')) {
      const { BotSuggestions } = messages[0];
      botSuggestionsContainerValue = (
        <BotSuggestionsContainer
          onSendMessage={this.onSuggestionSelect}
          botSuggestions={BotSuggestions}
          userDetails={userDetails}
          messageValue={messageValue}
        />
      );
    }

    if (!userDetails) {
      return null;
    }
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          {favIcon ? (
            <link rel="shortcut icon" href={favIcon} />
          ) : (
            <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />
          )}
        </Helmet>
        <div className="chat-window">
          <div className="candidate-chat-section chat-window-section">
            <div className="chat-header">
              <div className="bot-title-image">
                <div>
                  {favIcon ? (
                    <img src={favIcon} alt={name} className="bot-favicon" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/favicon.png`} alt={name} className="default-icon" />
                  )}
                </div>
                <div className="bot-title">{name}</div>
              </div>
              <div className="powered-by">
                <div className="bot-powered-by">Powered by</div>
                <div>
                  {icon ? (
                    <img src={icon} alt="Arya" className="bot-favicon" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/favicon.png`} alt="Arya" className="default-icon" />
                  )}
                </div>
              </div>
            </div>
            <ChatPane
              className="messages-wrapper"
              totalMessagesCount={totalMessagesCount}
              fetchMessages={fetchMessages}
              currentMessagesCount={messages.length}
            >
              {botSuggestionsContainerValue}
              {botTypingDisplayValue}
              {messages.map(message => (
                <MessageBubble
                  key={message.Id}
                  id={message.Id}
                  message={message.Body}
                  align={message.IsByPerson ? 'right' : 'left'}
                  status={message.RequestStatus}
                  timestamp={message.CreatedTime}
                  name={getMessageSourceName(message, userDetails)}
                />
              ))}
            </ChatPane>

            <div className="chat-input">
              <TextArea
                placeholder="Type a message"
                style={{ height: '50px', resize: 'none' }}
                autoSize={{ maxRows: 3 }}
                onPressEnter={isEnabled && this.onClickSendMessage}
                value={messageValue}
                onChange={this.onMessageChange}
              />
              <Button
                type="primary"
                className="chat-send-button"
                onClick={this.onClickSendMessage}
                disabled={!isEnabled}
              >
                <Icon type="arrow-right" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CandidateChatWindow;
