import _ from 'lodash';
import * as EmailVerificationActions from '../Actions/ActionCreators/EmailVerificationActions';

function EmailVerificationReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case EmailVerificationActions.SET_VERIFICATION_ACTION_STATUS:
      newState = _.cloneDeep(state);
      newState.verificationActionStatus = action.payload;
      return newState;

    case EmailVerificationActions.SET_VERIFICATION_STATUS:
      newState = _.cloneDeep(state);
      newState.verificationStatus = action.payload;
      return newState;

    default:
      return state;
  }
}

function getEmailVerificationStatus(state) {
  return _.get(state, ['EmailVerificationReducer', 'verificationStatus'], {});
}

function getEmailVerificationActionStatus(state) {
  return _.get(state, ['EmailVerificationReducer', 'verificationActionStatus'], '');
}

export { EmailVerificationReducer, getEmailVerificationStatus, getEmailVerificationActionStatus };
