import _ from 'lodash';

const initialState = {
  WhiteLabelInfo: {},
};

function ConfigReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'SET_WHITE_LABEL_DATA':
      newState = _.cloneDeep(state);
      newState.WhiteLabelInfo = action.payload;
      return newState;
    default:
      return state;
  }
}

function getWhiteLabelInformation(state) {
  return _.get(state, ['ConfigReducer', 'WhiteLabelInfo'], {});
}

export { ConfigReducer, getWhiteLabelInformation };
