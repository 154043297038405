function isValidDayForUser(userConfig, dateTime) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return userConfig.AvailableWeekDays.includes(days[dateTime.getDay()]);
}

function getNormalizedUserStartAndEndTime(userCalendarConfig) {
  const localTime = new Date();
  const userOffset = localTime.getTimezoneOffset();
  const userStartTimeString = userCalendarConfig.StartingFreeTime.split(':');
  const userEndTimeString = userCalendarConfig.EndingFreeTime.split(':');
  let userStartTimeMinutes = userStartTimeString[0] * 60 + userStartTimeString[1] * 1 + userStartTimeString[2] / 60;
  let userEndTimeMinutes = userEndTimeString[0] * 60 + userEndTimeString[1] * 1 + userEndTimeString[2] / 60;
  userStartTimeMinutes -= userOffset;
  userEndTimeMinutes -= userStartTimeMinutes + userOffset;
  if (userEndTimeMinutes < 0) userEndTimeMinutes += 1440;
  return { userStartTimeMinutes, userEndTimeMinutes };
}

function getValidSlotDateTime(slotStartDateTime, validSlotStartMinutes, userStartTimeMinutes, userSlotDuration) {
  const slot = {};
  slot.StartDateTime = new Date(
    slotStartDateTime.getFullYear(),
    slotStartDateTime.getMonth(),
    slotStartDateTime.getDate(),
    0,
    0,
    0
  );
  slot.EndDateTime = new Date(
    slotStartDateTime.getFullYear(),
    slotStartDateTime.getMonth(),
    slotStartDateTime.getDate(),
    0,
    0,
    0
  );
  const slotStartMinutes = validSlotStartMinutes + userStartTimeMinutes;
  slot.StartDateTime.setMinutes(slotStartMinutes);
  slot.EndDateTime.setMinutes(slotStartMinutes + userSlotDuration);
  return slot;
}

export { isValidDayForUser, getNormalizedUserStartAndEndTime, getValidSlotDateTime };
