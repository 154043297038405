import React from 'react';
import { Form, Divider, Button, Checkbox } from 'antd';
import { Helmet } from 'react-helmet';
import './Unsubscribe.scss';

class Unsubscribe extends React.Component {
  handleSubmit = e => {
    const { form, updateStatus } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (values.unsubscribeTypes.includes('all')) {
          updateStatus(true);
        } else {
          updateStatus(false);
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const isDisbale = (getFieldValue('unsubscribeTypes') || []).length > 0;

    return (
      <div>
        <Helmet>
          <title>Unsubscribe</title>
          <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
        </Helmet>
        <div className="unsubscribe">
          <div className="unsubscribe-oval">
            <img src={`${process.env.PUBLIC_URL}/unsubscribe.svg`} alt="unsubscribe" className="unsubscribe-icon" />
          </div>
          <div className="unsubscribe-are-you-sure">Are you sure ?</div>
          <div className="unsubscribe-message">
            This means you will no longer receive emails about open positions relevant to your experience, skills, and
            career development.
          </div>
          <Divider />
          <div className="unsubscribe-do-you-really">
            Do you really want to give up taking a look at new career opportunities now and then?
          </div>
          <Form className="unsubscribe-form" onSubmit={this.handleSubmit}>
            <Form.Item>
              {getFieldDecorator('unsubscribeTypes', {
                initialValue: ['all'],
              })(
                <Checkbox.Group>
                  <div className="unsubscribe-div-wrapper">
                    <Checkbox value="all" className="unsubscribe-option-text">
                      Unsubscribe me from all emails
                    </Checkbox>
                  </div>
                </Checkbox.Group>
              )}
            </Form.Item>
            <Form.Item>
              <Button disabled={!isDisbale} htmlType="submit" className="unsubscribe-button">
                Unsubscribe
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(Unsubscribe);
