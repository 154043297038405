import axios from 'axios';
import Config from '../Config/Config'

const connectV1Client = axios.create({
  baseURL: Config.urls.aryaConnect,
});

const connectCalendarClient = axios.create({
  baseURL: Config.urls.aryaCalendar,
});

export {connectV1Client, connectCalendarClient};
