const Config = {
  urls: {
    aryaCalendar: 'https://calendar.goarya.com',
    aryaConnectNotifications: 'https://connectapi.goarya.com/hubs/connect',
    aryaConnect: 'https://connectapi.goarya.com/api/v1',
    getWhiteLabelResourceUrl: client => `https://arya3-whitelabel.s3.amazonaws.com/${client.toLowerCase()}-prod`,
    aryaUrl: 'https://app.goarya.com',
  },
};

export default Config;
