import {connectV1Client, connectCalendarClient} from './BaseRepository';

export function getBotDetails(botContextId) {
  return connectV1Client.get(`BotContexts/${botContextId}/botdetails`);
}

export function getBotContextId(conversationId) {
  return connectV1Client.get(`Conversations/${conversationId}`);
}

export function getUserEmail(userId) {
  return connectV1Client.get(`Users/${userId}`);
}

export function getCalendarSlots(userId, startDate, endDate) {
  return connectCalendarClient.get(`freeslots?start_date=${startDate}&end_date=${endDate}`, {
    headers: {'username':userId,
              'x-access-token':'axxsresdfgcddc-ss-sd',
              'application':'arya'}
  });
}

export function getUserAvailabilityConfig(userId) {
  return connectCalendarClient.get(`_config`, {
    headers: {'username':userId,
              'x-access-token':'axxsresdfgcddc-ss-sd',
              'application':'arya'
  }
  });
}
