import React from 'react';
import { Helmet } from 'react-helmet';
import './SubscribeSuccess.scss';

export default function SubscribeSuccess() {
  return (
    <div>
      <Helmet>
        <title>Subscribed</title>
        <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
      </Helmet>
      <div className="subscribed">
        <div className="subscribed-oval">
          <img src={`${process.env.PUBLIC_URL}/resubscribed.svg`} alt="subscribed" className="subscribed-icon" />
        </div>
        <div className="subscribed-congratulations">Congratulations</div>
        <div className="subscribed-message">
          You will continue to receive our emails as and when we come across new job opportunities relevant to your
          experience.
        </div>
      </div>
    </div>
  );
}
