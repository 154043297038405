import React from 'react';
import { Helmet } from 'react-helmet';
import './OptinSuccess.scss';

function OptinSuccess() {
  return (
    <div>
      <Helmet>
        <title>OptinSuccess</title>
        <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
      </Helmet>

      <div className="optin-success-oval">
        <img src={`${process.env.PUBLIC_URL}/resubscribed.svg`} alt="resubscribe" className="optin-success-icon" />
      </div>
      <div className="optin-success">
        <div className="optin-success-wrapper">
          <div className="optin-success-content">
            <div className="optin-success-are-you-sure"> Congratulations</div>
            <div className="optin-success-message">
              You will continue to receive our emails as and when we come across new job opportunities relevant to your
              experience.
            </div>
            <div className="optin-success-footer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptinSuccess;
