/* eslint-disable react/no-danger */
import React from 'react';
import queryString from 'query-string';
import DOMPurify from 'dompurify';
import InfiniteScroll from 'react-infinite-scroller';
import linkify from '../../Utils/HyperLinkUtils';
import { getChatRelativeTime } from '../../Utils/RelativeTimeCalculator';
import './ChatPane.scss';

export default class ChatPane extends React.Component {
  static MessageBubble = ({ message, align, id, timestamp, status, name }) => {
    return (
      <div key={id} className={`connect-message-bubble-wrapper ${align}`}>
        <div
          className={`connect-message-bubble connect-message-bubble ${align} ${status}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkify(message), {ALLOWED_ATTR: ['target', 'href']}) }}
        ></div>
        <div className={`connect-message-bubble-time connect-message-bubble-time ${align}`}>
          {`${name} ${getChatRelativeTime(timestamp)}`}
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);
    this.messagesWrapper = React.createRef();
  }

  render() {
    const { children, className, fetchMessages, totalMessagesCount, currentMessagesCount } = this.props;
    const { conversationId } = queryString.parse(window.location.search);
    return (
      <div ref={this.messagesWrapper} className={`connect-message-window-flex-items messages-wrapper ${className}`}>
        <div className="inner-messages-wrapper">
          <InfiniteScroll
            className="chat-window-body-infinite-scroll"
            useWindow={false}
            loadMore={page => fetchMessages(conversationId, page, 30)}
            initialLoad={false}
            hasMore={currentMessagesCount < totalMessagesCount}
            threshold={200}
            isReverse
            getScrollParent={() => this.messagesWrapper.current}
          >
            {children}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
