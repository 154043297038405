import _ from 'lodash';

function UserReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'SET_USER_DETAILS':
      newState = _.cloneDeep(state);
      newState.userDetails = action.payload;
      return newState;
    default:
      return state;
  }
}

function getUserDetails(state) {
  return state.UserReducer.userDetails;
}

export { UserReducer, getUserDetails };
