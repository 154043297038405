import {
  connectV1Client
} from './BaseRepository';

function updateSubscriptionStatus(EmailConversationId, IsAllConversations, Status) {
  return connectV1Client.post(`_pa/subscriptionStatus`, {
    EmailConversationId,
    IsAllConversations,
    Status,
  });
}

function updateConsentStatus(EmailConversationId, Status) {
  return connectV1Client.post(`_pa/consentStatus`, {
    EmailConversationId,
    Status,
  });
}

export {
  updateSubscriptionStatus,
  updateConsentStatus,
}
