import React from 'react';
import { Button } from 'antd';
import './FiltersSuggestion.scss';

export default class FiltersSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
  }

  handleFilterSelect(text) {
    const { onSendMessage, setSuggestionsDisplayStatus } = this.props;
    onSendMessage(text);
    setSuggestionsDisplayStatus(false);
  }

  render() {
    const { filteringTexts } = this.props;
    const filteringTextComponents = [];
    filteringTexts.forEach(text => {
        filteringTextComponents.push(
          <Button type="primary" onClick={() => this.handleFilterSelect(text)} className={`filter-suggestion ${text}`}>
            {text}
          </Button>
        );
    });
    return <div className="filter-suggestions">{filteringTextComponents}</div>;
  }
}
