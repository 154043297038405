import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import OptoutSuccess from '../Components/OptoutSuccess/OptoutSuccess';
import * as PersonActions from '../Actions/PersonActivityAction';

class OptoutSuccessContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPage: false,
    };
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { ref } = queryString.parse(location.search);
    if (!ref) {
      this.setState({
        showErrorPage: true,
      });
    } else {
      this.setState({
        ref,
      });
    }
  }

  updateStatus() {
    const { history } = this.props;
    const { ref } = this.state;
    PersonActions.updateConsentStatus(ref, 'Approved', history, 'OptinSuccess');
  }

  render() {
    const { showErrorPage } = this.state;
    if (showErrorPage) {
      return <div>Invalid URL</div>;
    }
    return <OptoutSuccess updateStatus={this.updateStatus} />;
  }
}

export default withRouter(OptoutSuccessContainer);
