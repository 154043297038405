import _ from 'lodash';
import { setBotStatus, addMessage, setIsMessageFromUser } from '../Actions/ChatActions';

export default function setChatEvents(connection, dispatch) {
  connection.on('ReceiveChatMessage', message => {
    dispatch(addMessage(message));
    if (!message.IsByPerson && !message.IsByBot && _.get(message, ['CreatedBy'], false)) {
      dispatch(setIsMessageFromUser(true));
    }
  });
  connection.on('BotStatusChange', isBotEnabled => {
    dispatch(setBotStatus(isBotEnabled));
  });
}
