import _ from 'lodash';
import verifyAndGetRedirectUrl from '../Repository/EmailVerificationRepository';
import { setVerificationActionStatus, setVerificationStatus } from './ActionCreators/EmailVerificationActions';
import * as EmailVerificationMessageConstants from '../Constants/EmailVerificationMessageConstants';

const pending = 'PENDING';
const successfull = 'SUCCESSFULL';
const failed = 'FAILED';
const verified = 'VERIFIED';

export default function verifyEmail(verificationDetails) {
  return dispatch => {
    dispatch(setVerificationActionStatus(pending));
    verifyAndGetRedirectUrl(verificationDetails)
      .then(response => {
        dispatch(setVerificationStatus(verified, EmailVerificationMessageConstants.verifiedMessage, response.data));
        dispatch(setVerificationActionStatus(successfull));
      })
      .catch(exception => {
        const error = _.get(exception, ['response', 'data', 'Error'], '');
        const errorCode = _.get(error, ['Code'], '');
        const applicationDetails = _.get(error, ['Details', 'Application'], {});
        if (errorCode === 'LINK_EXPIRED') {
          dispatch(
            setVerificationStatus(failed, EmailVerificationMessageConstants.linkExpiredMessage, applicationDetails)
          );
        } else if (errorCode === 'EMAILID_NOT_FOUND') {
          dispatch(
            setVerificationStatus(failed, EmailVerificationMessageConstants.notFoundMessage, applicationDetails)
          );
        } else {
          dispatch(setVerificationStatus(failed, EmailVerificationMessageConstants.unknownErrorMessage));
        }
        dispatch(setVerificationActionStatus(successfull));
      });
  };
}
