import React from 'react';
import { Button, Select } from 'antd';
import getCurrentTimeZoneTime from '../../Utils/TimeUtils';

class SlotSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedSlot: null,
    };
    this.selectSlotkey = 0;
  }

  onSlotBook() {
    const { selectedSlot, selectedDate } = this.state;
    const { onSendMessage, setSuggestionsDisplayStatus, setValidSlotsEmpty } = this.props;
    if (selectedSlot && selectedDate) {
      const { startTime } = selectedSlot;
      const startTimeArr = startTime.split(':');
      const startHour = startTimeArr[0];
      const startMinute = startTimeArr[1];
      const date = selectedDate.split('/');
      const month = date[0];
      const day = date[1];
      const year = date[2];
      let startDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, day, startHour, startMinute);
      startDate = getCurrentTimeZoneTime(startDate);
      onSendMessage(startDate);
      setSuggestionsDisplayStatus(false);
      setValidSlotsEmpty();
    }
  }

  handleSlotSelect(value) {
    const slot = {};
    const slotSplittedArr = value.split(' - ');
    [slot.startTime, slot.endTime] = slotSplittedArr;
    this.setState({
      selectedSlot: slot,
    });
  }

  handleDateSelect(value) {
    this.setState({
      selectedDate: value,
      selectedSlot: null,
    });
  }

  isSaveDisabled() {
    const { selectedSlot, selectedDate } = this.state;
    if (!selectedSlot || !selectedDate) {
      return true;
    }
    return false;
  }

  render() {
    this.selectSlotkey += 1;
    const { validSlots } = this.props;
    const { selectedDate, selectedSlot } = this.state;
    const { Option } = Select;
    const dateTimeSlots = {};
    const dates = [];

    if (!(validSlots && validSlots.length > 0)) {
      return null;
    }

    validSlots.forEach(validSlot => {
      const day = validSlot.StartDateTime.getDate();
      const month = validSlot.StartDateTime.getMonth();
      const year = validSlot.StartDateTime.getFullYear();
      const date = new Date(year, month, day).toLocaleDateString('en-US');
      if (!dates.includes(date)) {
        dates.push(date);
      }
      const timeSlot = {};
      let startHour = validSlot.StartDateTime.getHours();
      if (startHour < 10) {
        startHour = `${0}${startHour}`;
      }
      let startMinute = validSlot.StartDateTime.getMinutes();
      if (startMinute < 10) {
        startMinute = `${0}${startMinute}`;
      }

      timeSlot.startTime = `${startHour}:${startMinute}`;

      let endHour = validSlot.EndDateTime.getHours();
      if (endHour < 10) {
        endHour = `${0}${endHour}`;
      }
      let endMinute = validSlot.EndDateTime.getMinutes();
      if (endMinute < 10) {
        endMinute = `${0}${endMinute}`;
      }
      timeSlot.endTime = `${endHour}:${endMinute}`;
      if (!dateTimeSlots[date]) {
        dateTimeSlots[date] = [];
      }
      dateTimeSlots[date].push(timeSlot);
    });

    let timeSlotContent = (
      <Select placeholder="Select Slot" className="slot-select" onSelect={value => this.handleSlotSelect(value)} />
    );

    if (selectedDate && !selectedSlot) {
      timeSlotContent = (
        <Select
          placeholder="Select Slot"
          key={this.selectSlotkey}
          onSelect={value => this.handleSlotSelect(value)}
          className="slot-select"
        >
          {dateTimeSlots[selectedDate].map(slot => (
            <Option value={`${slot.startTime} - ${slot.endTime}`}>{`${slot.startTime} - ${slot.endTime}`}</Option>
          ))}
        </Select>
      );
    } else if (selectedDate && selectedSlot) {
      timeSlotContent = (
        <Select
          key={this.selectSlotkey}
          placeholder="Select Slot"
          value={`${selectedSlot.startTime} - ${selectedSlot.endTime}`}
          onSelect={value => this.handleSlotSelect(value)}
          className="slot-select"
        >
          {dateTimeSlots[selectedDate].map(slot => (
            <Option value={`${slot.startTime} - ${slot.endTime}`}>{`${slot.startTime} - ${slot.endTime}`}</Option>
          ))}
        </Select>
      );
    }

    return (
      <div className="bot-suggestion-content">
        <Select placeholder="Select Date" onSelect={value => this.handleDateSelect(value)} className="date-select">
          {dates.map(date => (
            <Option value={date}>{date}</Option>
          ))}
        </Select>
        {timeSlotContent}
        <Button
          type="primary"
          className="chat-send-button"
          disabled={this.isSaveDisabled()}
          onClick={() => this.onSlotBook()}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default SlotSuggestion;
