import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  getMessages,
  getBotContextId,
  getBotData,
  getBotTypingStatus,
  getBotEnabledStatus,
  getTotalMessagesCount,
  getIsMessageFromUser,
} from '../Reducers/ChatReducer';
import { getUserDetails } from '../Reducers/UserReducer';
import * as ChatActions from '../Actions/ChatActions';
import { setupSocketConnections } from '../Events/SocketConnectionManager';
import CandidateChatWindow from '../Components/CandidateChatWindow/CandidateChatWindow';

const mapStateToProps = state => ({
  messages: getMessages(state),
  botContextId: getBotContextId(state),
  botDetails: getBotData(state),
  userDetails: getUserDetails(state),
  botTypingStatus: getBotTypingStatus(state),
  isBotEnabled: getBotEnabledStatus(state),
  totalMessagesCount: getTotalMessagesCount(state),
  isMessageFromUser: getIsMessageFromUser(state),
});

const mapDispatchToProps = {
  sendMessage: ChatActions.sendMessage,
  fetchMessages: ChatActions.fetchMessages,
  getBotDetails: ChatActions.getBotDetails,
  createSocketConnection: setupSocketConnections,
};

class CandidateChatWindowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPage: false,
    };
    this.onSendMessage = this.onSendMessage.bind(this);
  }

  componentDidMount() {
    const { location, fetchMessages, getBotDetails, createSocketConnection } = this.props;
    const { personId, conversationId } = queryString.parse(location.search);
    if (!personId || !conversationId) {
      this.setState({
        showErrorPage: true,
      });
    } else {
      this.setState({
        personId,
        conversationId,
      });
    }
    createSocketConnection(['chat']);
    getBotDetails(conversationId);
    fetchMessages(conversationId, 0, 30);
  }

  onSendMessage(message) {
    const { sendMessage, botContextId } = this.props;
    const { personId, conversationId } = this.state;
    sendMessage({ message, botContextId, personId, conversationId });
  }

  render() {
    const {
      messages,
      botDetails,
      userDetails,
      botTypingStatus,
      isBotEnabled,
      fetchMessages,
      totalMessagesCount,
      isMessageFromUser
    } = this.props;
    const { showErrorPage } = this.state;
    if (showErrorPage) {
      return <div>Invalid URL</div>;
    }
    return (
      <CandidateChatWindow
        messages={messages}
        onSendMessage={this.onSendMessage}
        botDetails={botDetails}
        userDetails={userDetails}
        botTypingStatus={botTypingStatus}
        isBotEnabled={isBotEnabled}
        fetchMessages={fetchMessages}
        totalMessagesCount={totalMessagesCount}
        isMessageFromUser={isMessageFromUser}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateChatWindowContainer);
