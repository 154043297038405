import React from 'react';
import { connect } from 'react-redux';
import { getSuggestionStatus } from '../Reducers/ChatReducer';
import * as ChatActions from '../Actions/ChatActions';
import SlotSuggestionContainer from './SlotSuggestion';
import FilterSuggestionContainer from './FiltersSuggestion';
import QuestionSuggestionContainer from './QuestionSuggestion';

const mapStateToProps = state => ({
  showSuggestions: getSuggestionStatus(state),
});

const mapDispatchToProps = {
  setSuggestionsDisplayStatus: ChatActions.setSuggestionsDisplayStatus,
};

class BotSuggestionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestionType: null,
    };
  }

  componentDidMount() {
    const { botSuggestions, setSuggestionsDisplayStatus } = this.props;
    this.setState({
      suggestionType: botSuggestions[0].SuggestionType,
    });
    setSuggestionsDisplayStatus(true);
  }

  render() {
    const {
      showSuggestions,
      onSendMessage,
      setSuggestionsDisplayStatus,
      botSuggestions,
      userDetails,
      messageValue,
    } = this.props;
    const { suggestionType } = this.state;
    if (showSuggestions) {
      if (suggestionType === 'Appointment') {
        return (
          <SlotSuggestionContainer
            onSendMessage={onSendMessage}
            setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
            userDetails={userDetails}
          />
        );
      }
      if (suggestionType === 'Filtering') {
        const filteringTexts = botSuggestions
          .filter(suggestion => suggestion.SuggestionType === 'Filtering')
          .map(suggestion => suggestion.SuggestionText);
        return (
          <FilterSuggestionContainer
            filteringTexts={filteringTexts}
            userDetails={userDetails}
            onSendMessage={onSendMessage}
            setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
          />
        );
      }

      if (suggestionType === 'Questions') {
        const suggestedQuestions = botSuggestions
          .filter(suggestion => suggestion.SuggestionType === 'Questions')
          .map(suggestion => suggestion.SuggestionText);
        return (
          <QuestionSuggestionContainer
            suggestedQuestions={suggestedQuestions}
            userDetails={userDetails}
            onSendMessage={onSendMessage}
            setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
            messageValue={messageValue}
          />
        );
      }
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BotSuggestionsContainer);
