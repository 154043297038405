import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { ChatReducer } from './Reducers/ChatReducer';
import { UserReducer } from './Reducers/UserReducer';
import { EmailVerificationReducer } from './Reducers/EmailVerificationReducer';
import { ConfigReducer } from './Reducers/ConfigReducer';

const combinedReducer = combineReducers({ ChatReducer, UserReducer, EmailVerificationReducer, ConfigReducer });

let composeEnhancers = compose;
let middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, logger];
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
const store = createStore(combinedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
