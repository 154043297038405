import React from 'react';
import './QuestionSuggestion.scss';

export default class Question extends React.Component {
  handleQuestionSelect = () => {
    const { handleQuestionSelect, question } = this.props;
    handleQuestionSelect(question);
  };

  render() {
    const { question } = this.props;
    return (
      <div
        className="question-wrapper"
        onKeyDown={this.handleQuestionSelect}
        role="button"
        tabIndex="0"
        onClick={this.handleQuestionSelect}
      >
        <span className="question-suggestion">{question}</span>
      </div>
    );
  }
}
