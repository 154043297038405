import React from 'react';
import FiltersSuggestion from '../Components/BotSuggestions/FiltersSuggestion';

export default class FiltersSuggestionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { filteringTexts, onSendMessage, setSuggestionsDisplayStatus } = this.props;
    return (
      <FiltersSuggestion
        filteringTexts={filteringTexts}
        onSendMessage={onSendMessage}
        setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
      />
    );
  }
}
