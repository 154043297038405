function getMessageSourceName(message, userDetails) {
  let source = 'You';
  if (message.IsByBot) {
    source = 'AryaBot';
  } else if (!message.IsByBot && !message.IsByPerson) {
    source = `${userDetails.FirstName} ${userDetails.LastName}`;
  }
  return source;
}

export default getMessageSourceName;
