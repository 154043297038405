import moment from 'moment';

export function getRelativeTime(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.calendar(null, {
    sameDay: '[Today], hh:mm A',
    lastDay: '[Yesterday], hh:mm A',
    lastWeek: 'dddd, hh:mm A',
    sameElse: 'DD/MM/YYYY, hh:mm A',
  });
}

export function getRelativeDay(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: 'dddd',
    sameElse: 'L',
  });
}

// this function it get relative time for chat
export function getChatRelativeTime(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.calendar(null, {
    lastDay: '[Yesterday], hh:mm A',
    sameDay: 'hh:mm A',
    lastWeek: 'dddd, hh:mm A',
    sameElse: 'L, hh:mm A',
  });
}
