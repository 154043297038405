import React from 'react';
import QuestionSuggestion from '../Components/BotSuggestions/QuestionSuggestion';

export default class QuestionSuggestionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.suggestionKey = 0;
  }

  render() {
    this.suggestionKey += 1;
    const { suggestedQuestions, onSendMessage, setSuggestionsDisplayStatus, messageValue } = this.props;
    const matchingSuggestedQuestions = [];
    if (messageValue !== null && messageValue !== '') {
      suggestedQuestions.forEach(ques => {
        if (ques.toLowerCase().startsWith(messageValue.toLowerCase())) {
          matchingSuggestedQuestions.push(ques);
        }
      });
    }
    return (
      <QuestionSuggestion
        key={this.suggestionKey}
        suggestedQuestions={matchingSuggestedQuestions}
        onSendMessage={onSendMessage}
        setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
        messageValue={messageValue}
      />
    );
  }
}
