import React from 'react';
import { message } from 'antd';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Unsubscribe from '../Components/Unsubscribe/Unsubscribe';
import UnsubscribeCandidate from '../Components/UnsubscribeCandidate/Unsubscribe';
import * as PersonActions from '../Actions/PersonActivityAction';

class UnsubscribeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPage: false,
      unsubscribeValue: null,
      pageState: 'initial',
    };
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { ref, isRecruiter } = queryString.parse(location.search);
    this.setState(state => ({ ...state, isRecruiter }));
    if (!ref) {
      this.setState({
        showErrorPage: true,
      });
    } else {
      this.setState({
        ref,
      });
    }
  }

  onChangeUnsubscribeCandidate = value => {
    this.setState(state => ({ ...state, unsubscribeValue: value }));
  };

  onSubmitUnsubscribeCandidate = () => {
    const { unsubscribeValue } = this.state;
    if (unsubscribeValue !== null) {
      this.updateStatus(unsubscribeValue === 'unsubscribeAll');
      this.setState(oldState => ({ ...oldState, pageState: 'clicked' }));
    } else message.warning('please select an option');
  };

  unsubscribePageState = pageState => {
    this.setState(state => ({ ...state, pageState }));
  };

  unSubscribeOptions = () => {
    const { location } = this.props;
    const { appType } = queryString.parse(location.search);
    const aryaUnsubscribeOptions = [
      {
        label: 'Unsubscribe above email id from current job/campaign',
        value: 'unsubscribeJob',
      },
      {
        label: 'Unsubscribe above email id from all mailing list',
        value: 'unsubscribeAll',
      },
    ];

    const atsUnsubscribeOptions = [
      {
        label: 'Unsubscribe above email id from all mailing list',
        value: 'unsubscribeAll',
      },
    ];
    if (appType === 'LeoAts') return atsUnsubscribeOptions;
    return aryaUnsubscribeOptions;
  };

  updateStatus(IsAllConversations) {
    const { history } = this.props;
    const { ref, isRecruiter } = this.state;
    if (isRecruiter === 'true') {
      PersonActions.updateSubscriptionStatus(
        ref,
        IsAllConversations,
        'Unsubscribed',
        null,
        'recruiterSuccess',
        this.unsubscribePageState
      );
    } else
      PersonActions.updateSubscriptionStatus(ref, IsAllConversations, 'Unsubscribed', history, 'UnSubscribeSuccess');
  }

  render() {
    const { showErrorPage, isRecruiter, unsubscribeValue, pageState } = this.state;
    if (showErrorPage) {
      return <div>Invalid URL</div>;
    }
    const { location } = this.props;
    if (isRecruiter === 'true') {
      const { jobId, jobTitle, jobLocation, candidateEmail, candidateName } = queryString.parse(location.search);
      const props = {
        jobId,
        jobTitle,
        jobLocation,
        candidateEmail,
        candidateName,
      };
      return (
        <UnsubscribeCandidate
          {...props}
          updateStatus={this.updateStatus}
          state={{ unsubscribeValue, pageState }}
          onChangeUnsubscribeOptions={this.onChangeUnsubscribeCandidate}
          onSubmitUnsubscribeCandidate={this.onSubmitUnsubscribeCandidate}
          unSubscribeOptions={this.unSubscribeOptions}
        />
      );
    }
    return <Unsubscribe updateStatus={this.updateStatus} />;
  }
}

export default withRouter(UnsubscribeContainer);
export { UnsubscribeContainer as UnsubscribeContainerWithoutRouter };
