import { message } from 'antd';
import * as personRepo from '../Repository/PersonActivityRepository';

async function updateSubscriptionStatus(
  EmailConversationIds,
  IsAllConversations,
  Status,
  history,
  nextPage,
  updatePageState
) {
  let EmailConversationId = EmailConversationIds;
  if (Array.isArray(EmailConversationIds)) {
    [EmailConversationId] = EmailConversationIds;
  }
  try {
    const response = await personRepo.updateSubscriptionStatus(EmailConversationId, IsAllConversations, Status);
    if (nextPage === 'UnSubscribeSuccess') {
      history.push(`/unsubscribesuccess?ref=${EmailConversationId}`);
    }
    if (nextPage === 'SubscribeSuccess') {
      history.push(`/subscribesuccess`);
    }
    if (nextPage === 'recruiterSuccess') {
      if (response.status === 200) {
        updatePageState('success');
      }
    }
  } catch (e) {
    message.error('Something went wrong');
    updatePageState('failed');
  }
}

function updateConsentStatus(EmailConversationIds, Status, history, nextPage) {
  let EmailConversationId = EmailConversationIds;
  if (Array.isArray(EmailConversationIds)) {
    [EmailConversationId] = EmailConversationIds;
  }
  personRepo.updateConsentStatus(EmailConversationId, Status).then(() => {
    if (nextPage === 'OptoutSuccess') {
      history.push(`/optoutsuccess?ref=${EmailConversationId}`);
    }
    if (nextPage === 'OptinSuccess') {
      history.push(`/optinsuccess`);
    }
  });
}

export { updateSubscriptionStatus, updateConsentStatus };
