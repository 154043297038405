import * as signalR from '@aspnet/signalr';
import Config from '../Config/Config';
import setChatEvents from './ChatEvents';

const connectUrl = Config.urls.aryaConnectNotifications;
// eslint-disable-next-line import/no-mutable-exports
let chatConnection = null;

function setupSocketConnections(connectionTypes) {
  return dispatch => {
    if (connectionTypes.includes('chat')) {
      // eslint-disable-next-line no-use-before-define
      setupChatConnection(dispatch);
    }
  };
}

function closeSocketConnections() {
  if (chatConnection) {
    chatConnection.stop();
    chatConnection = null;
  }
}

function setupChatConnection(dispatch) {
  if (chatConnection) {
    return;
  }
  const currentTime = new Date();
  const currentTimeZoneOffset = currentTime.getTimezoneOffset();
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${connectUrl}${window.location.search}&TimeZoneOffset=${currentTimeZoneOffset}`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();
  setChatEvents(connection, dispatch);
  connection
    .start()
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('ChatHub Connected');
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('ChatHub Connection failed due to', err, '. Retrying... in 0.5 sec');
      chatConnection = null;
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(setupSocketConnections(['chat'])), 500);
    });
  connection.onclose(res => {
    console.log('Chathub disconnected due to ', res, '. Reconnecting... in 0.5 sec');
    chatConnection = null;
    // eslint-disable-next-line no-use-before-define
    setTimeout(() => dispatch(setupSocketConnections(['chat'])), 500);
  });
  chatConnection = connection;
}

export { chatConnection, setupSocketConnections, setupChatConnection, closeSocketConnections };
