import React from 'react';
import { Form, Divider, Button } from 'antd';
import { Helmet } from 'react-helmet';
import './OptoutSuccess.scss';

class OptoutSuccess extends React.Component {
  handleSubmit = e => {
    const { form, updateStatus } = this.props;
    e.preventDefault();
    form.validateFields(err => {
      if (!err) {
        updateStatus();
      }
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Optin</title>
          <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />)
        </Helmet>

        <div className="optin-oval">
          <img src={`${process.env.PUBLIC_URL}/unsubscribed.svg`} alt="unsubscribe" className="optin-icon" />
        </div>
        <div className="optin">
          <div className="optin-wrapper">
            <div className="optin-content">
              <div className="optin-are-you-sure"> You will be missed!</div>
              <div className="optin-message">
                You have been successfully removed from Company opted-in list. You will no longer hear from us.
              </div>
              <Divider />
              <div className="optin-do-you-really">
                Didn’t mean to opt-out? No problem, Opt-in to this list with a single click.
              </div>
              <div className="optin-form">
                <div className="optin-submit-wrapper ">
                  <div>
                    <Button type="primary" className="optin-button" onClick={this.handleSubmit}>
                      Opt-in
                    </Button>
                  </div>
                  {/* <div className="optin-or">Or</div>
                  <div>
                    <Button className="optin-button"> Checkout client website </Button>
                  </div> */}
                </div>
              </div>
              <div className="optin-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(OptoutSuccess);
