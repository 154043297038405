export const SET_VERIFICATION_ACTION_STATUS = 'SET_VERIFICATION_ACTION_STATUS';
export const SET_VERIFICATION_STATUS = 'SET_VERIFICATION_STATUS';

export function setVerificationActionStatus(status) {
  return {
    type: 'SET_VERIFICATION_ACTION_STATUS',
    payload: status,
  };
}

export function setVerificationStatus(status, message, application) {
  return {
    type: 'SET_VERIFICATION_STATUS',
    payload: {
      status,
      message,
      application,
    },
  };
}
