import React from 'react';
import { connect } from 'react-redux';
import { getValidSlots } from '../Reducers/ChatReducer';
import { getDateString, addDaysToDate } from '../Utils/DateUtils';
import * as ChatActions from '../Actions/ChatActions';
import SlotSuggestion from '../Components/BotSuggestions/SlotSuggestion';

const mapStateToProps = state => ({
  validSlots: getValidSlots(state),
});

const mapDispatchToProps = {
  getCalendarFreeSlots: ChatActions.getCalendarFreeSlots,
  setValidSlotsEmpty: ChatActions.setValidSlotsEmpty,
};

class SlotSuggestionContainer extends React.Component {
  componentDidMount() {
    const { getCalendarFreeSlots, userDetails } = this.props;
    const startDate = new Date();
    const startDateString = getDateString(startDate);
    const endDate = addDaysToDate(startDate, 5);
    const endDateString = getDateString(endDate);
    getCalendarFreeSlots(userDetails.Email, startDateString, endDateString);
  }

  render() {
    const { validSlots, onSendMessage, setSuggestionsDisplayStatus, setValidSlotsEmpty } = this.props;
    if (validSlots && validSlots.length > 0) {
      return (
        <SlotSuggestion
          validSlots={validSlots}
          onSendMessage={onSendMessage}
          setSuggestionsDisplayStatus={setSuggestionsDisplayStatus}
          setValidSlotsEmpty={setValidSlotsEmpty}
        />
      );
    }
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlotSuggestionContainer);
